import { useAtomValue } from "jotai"
import { CirclePlay } from "lucide-react"
import { Redirect } from "wouter"

import { useGetRunsQuery } from "@/api/repo"
import Loading from "@/components/Loading"
import PageLayout from "@/components/PageLayout"
import PageTitle from "@/components/PageTitle"
import RunTable from "@/components/run/RunTable"
import { Button } from "@/components/ui/button"
import NewRun from "@/features/runs/NewRun"
import { currentRepositoryAtom } from "@/state"

const RunList = () => {
  const repository = useAtomValue(currentRepositoryAtom)
  const { data: runs, isLoading } = useGetRunsQuery(repository?.id)
  if (isLoading) return <Loading />
  if (!runs || !repository) return <Redirect to="/" />

  return (
    <PageLayout
      title={
        <PageTitle
          title="Runs"
          actions={
            <NewRun>
              <Button>
                <CirclePlay />
                Trigger a new run
              </Button>
            </NewRun>
          }
          Icon={CirclePlay}
        />
      }
    >
      <div className="flex flex-col gap-y-4">
        <RunTable repo={repository} runs={runs} />
      </div>
    </PageLayout>
  )
}

export default RunList
