import { App } from "api"
import { hc } from "hono/client"

import { getTokens } from "@/auth/jwt"

const baseApiUrl =
  import.meta.env.VITE_API_BASE_URL ?? process.env.VITE_API_BASE_URL

if (!baseApiUrl) {
  throw new Error("VITE_API_BASE_URL is not set")
}

export const client = hc<App>(baseApiUrl)
export const getAuthenticatedClient = () =>
  hc<App>(baseApiUrl, {
    headers: {
      Authorization: "Bearer " + getTokens().jwt,
    },
  })
