import { Check, Scale, SearchCheck } from "lucide-react"
import { Link } from "wouter"

import AddToRepository from "@/components/AddToRepository"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import NewRun from "@/features/runs/NewRun"

interface Props {
  hasRepo: boolean
  hasRules: boolean
  hasRuns: boolean
}
const Onboarding = ({ hasRepo, hasRules, hasRuns }: Props) => {
  return (
    <div className="flex flex-col gap-y-2 px-4 w-full h-full items-center justify-center">
      <Card className="max-w-[500px]">
        <CardHeader className="w-full">
          <CardTitle className="font-logo font-bold px-2 mb-4 text-2xl text-center">
            Start using ReviewPal
          </CardTitle>
          <CardContent className="flex gap-y-8 flex-col">
            <div className="flex flex-col gap-y-2">
              <div className="flex gap-x-2 items-center">
                <div className="font-bold bg-gray-800 text-white w-8 h-8 rounded-full flex items-center justify-center">
                  {hasRepo ? <Check size={16} /> : "1"}
                </div>
                <div className="font-semibold">
                  Add the app to one of your GitHub Repositories
                </div>
              </div>
              {!hasRepo && (
                <div className="text-sm ml-10">
                  <strong className="font-logo">ReviewPal</strong> automatically
                  plugs into your GitHub repositories to help you find issues in
                  your Pull Requests.
                </div>
              )}
              {!hasRepo && (
                <div className="mt-4">
                  <AddToRepository />
                </div>
              )}
            </div>
            {hasRepo && (
              <div className="flex flex-col gap-y-4">
                <div className="flex gap-x-2 items-center">
                  <div className="font-bold bg-gray-800 text-white w-8 h-8 rounded-full flex items-center justify-center">
                    {hasRules ? <Check size={16} /> : "2"}
                  </div>
                  <div className="font-semibold">
                    Create a <Link href="/rules/new">rule</Link>
                  </div>
                </div>
                {!hasRules && (
                  <div className="text-sm ml-10">
                    <strong className="font-logo">ReviewPal</strong> lets you
                    define rules with natural language to enforce your code
                    quality standards.
                  </div>
                )}
                {hasRepo && !hasRules && (
                  <Link to={`/rules/new`} className="flex w-full">
                    <Button className="mt-4 w-full" size="lg">
                      <Scale />
                      Create a rule
                    </Button>
                  </Link>
                )}
              </div>
            )}
            {hasRepo && hasRules && (
              <div className="flex flex-col gap-y-4">
                <div className="flex gap-x-2 items-center">
                  <div className="font-bold bg-gray-800 text-white w-8 h-8 rounded-full flex items-center justify-center">
                    {hasRuns ? <Check size={16} /> : "3"}
                  </div>
                  <div className="font-semibold">
                    Find issues in a Pull Request
                  </div>
                </div>
                <div className="text-sm ml-10">
                  Analyse a Pull Request to find issues that violate your rules.
                </div>
                {hasRepo && hasRules && !hasRuns && (
                  <NewRun>
                    <Button className="mt-4 w-full" size="lg">
                      <SearchCheck />
                      Start an analysis
                    </Button>
                  </NewRun>
                )}
              </div>
            )}
          </CardContent>
        </CardHeader>
      </Card>
    </div>
  )
}

export default Onboarding
