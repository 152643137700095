import { zodResolver } from "@hookform/resolvers/zod"
import { useAtomValue } from "jotai"
import { Settings } from "lucide-react"
import { Controller, useForm } from "react-hook-form"
import { z } from "zod"

import { useUpdateRepositorySettingsMutation } from "@/api/repo"
import PageLayout from "@/components/PageLayout"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { Label } from "@/components/ui/label"
import { Separator } from "@/components/ui/separator"
import { Switch } from "@/components/ui/switch"
import { useToast } from "@/hooks/use-toast"
import { currentRepositoryAtom } from "@/state"

export const RepositorySettings = () => {
  const { toast } = useToast()
  const repository = useAtomValue(currentRepositoryAtom)

  const form = useForm({
    resolver: zodResolver(
      z.object({
        runOnPrOpen: z.boolean(),
        runOnPrCommit: z.boolean(),
        submitPrCheck: z.boolean(),
        disabled: z.boolean(),
      }),
    ),
    defaultValues: {
      runOnPrOpen: repository?.settings.runOnPrOpen ?? false,
      runOnPrCommit: repository?.settings.runOnPrCommit ?? false,
      submitPrCheck: repository?.settings.submitPrCheck ?? false,
      disabled: repository?.settings.disabled ?? false,
    },
  })
  const mutation = useUpdateRepositorySettingsMutation(repository?.id, {
    onSuccess: () => {
      toast({ title: "Settings saved" })
    },
  })
  return (
    <form onSubmit={form.handleSubmit((data) => mutation.mutate(data))}>
      <PageLayout
        title={
          <div className="flex gap-x-4 items-center font-bold text-2xl justify-between">
            <div className="flex items-center gap-x-2">
              <Settings />
              Settings
            </div>
            <Button type="submit" loading={mutation.isPending}>
              Save
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-y-4">
          <div className="flex gap-x-2">
            <Controller
              name="runOnPrOpen"
              control={form.control}
              rules={{ required: true }}
              render={({ field }) => (
                <Checkbox
                  id="runOnPrOpen"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <div className="flex flex-col gap-y-2">
              <Label htmlFor="runOnPrOpen">Run on Pull Request open</Label>
              <p className="text-sm text-muted-foreground">
                Trigger a new analysis run whenever a Pull Request is opened.
              </p>
            </div>
          </div>
          <div className="flex gap-x-2">
            <Controller
              name="runOnPrCommit"
              control={form.control}
              rules={{ required: true }}
              render={({ field }) => (
                <Checkbox
                  id="runOnPrCommit"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <div className="flex flex-col gap-y-2">
              <Label htmlFor="runOnPrCommit">Run on Pull Request commit</Label>
              <p className="text-sm text-muted-foreground">
                Trigger a new analysis run whenever a commit is pushed on an
                open Pull Request.
              </p>
            </div>
          </div>

          <div className="flex gap-x-2">
            <Controller
              name="submitPrCheck"
              control={form.control}
              rules={{ required: true }}
              render={({ field }) => (
                <Checkbox
                  id="submitPrCheck"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <div className="flex flex-col gap-y-2">
              <Label htmlFor="submitPrCheck">
                Submit a <strong>❌ failing Pull Request check</strong> when one
                or more rules fail
              </Label>
              <p className="text-sm text-muted-foreground">
                This can be used to block Pull Requests from being merged if
                issues are found.
              </p>
            </div>
          </div>
          <Separator></Separator>
          <div className="flex gap-x-2">
            <Controller
              name="disabled"
              control={form.control}
              rules={{ required: true }}
              render={({ field }) => (
                <Switch
                  id="disabled"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <div className="flex flex-col">
              <Label htmlFor="disabled">
                Disable ReviewPal for this repository
              </Label>
            </div>
          </div>
        </div>
      </PageLayout>
    </form>
  )
}
export default RepositorySettings
