import { Route, Switch } from "wouter"

import AuthenticatedApp from "@/AuthenticatedApp"
import AdminLogin from "@/auth/AdminLogin"
import Logout from "@/auth/Logout"
import { Toaster } from "@/components/ui/toaster"
import GithubSetup from "@/features/setup/GithubSetup"

import "./App.css"
import GithubCallback from "./auth/GithubCallback"
import Login from "./auth/Login"

const App = () => {
  return (
    <>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/admin/login" component={AdminLogin} />
        <Route path="/logout" component={Logout} />
        <Route path="/auth/github/callback">
          <GithubCallback />
        </Route>
        <Route path="/github/setup">
          <GithubSetup />
        </Route>
        <Route path="/sentry-error">
          <button
            onClick={() => {
              throw new Error("This is your first error!")
            }}
          >
            Break the world
          </button>
        </Route>
        <Route>
          <AuthenticatedApp />
        </Route>
      </Switch>
      <Toaster />
    </>
  )
}

export default App
