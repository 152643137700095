import { ExternalLink } from "lucide-react"
import pluralize from "pluralize"

import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { JsonResponse } from "@/lib/api"

interface Props {
  repo: {
    fullName: string
  }
  runs: {
    id: string
    pullRequest: {
      githubNumber: string
      title: string
    }
    status: JsonResponse<"repository/:repoId/runs/$get">[0]["status"]
    sha: string
    githubCheckRunId: string | null
    createdAt: string
    _count: {
      issues: number
    }
  }[]
}

const RunTable = ({ repo, runs }: Props) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Pull Request</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>SHA</TableHead>
          <TableHead># issues</TableHead>
          <TableHead>Run at</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {runs.map((run) => (
          <TableRow key={run.id}>
            <TableCell>
              <a
                href={`https://github.com/${repo.fullName}/pull/${run.pullRequest.githubNumber}`}
                target="_blank"
              >
                <div className="flex gap-x-1 truncate">
                  <div className="flex">{run.pullRequest.title}</div>#
                  <div className="text-accent-foreground font-semibold">
                    {run.pullRequest.githubNumber}
                  </div>
                </div>
              </a>
            </TableCell>
            <TableCell
              className={`${run.status === "Completed" ? `text-green-700` : run.status === "Failed" ? `text-destructive` : ""}`}
            >
              <Badge
                variant={
                  run.status === "Completed"
                    ? "secondary"
                    : run.status === "Failed"
                      ? "destructive"
                      : "outline"
                }
              >
                {run.status}
              </Badge>
            </TableCell>
            <TableCell>
              <div className="flex">
                <Badge className="font-mono" variant="outline">
                  {run.sha.slice(0, 7)}
                </Badge>
              </div>
            </TableCell>
            <TableCell>
              <div className="flex">
                {pluralize("issue", run._count.issues, true)}
              </div>
            </TableCell>
            <TableCell>{new Date(run.createdAt).toLocaleString()}</TableCell>
            <TableCell>
              <a
                href={`https://github.com/${repo.fullName}/pull/${run.pullRequest.githubNumber}/checks?check_run_id=${run.githubCheckRunId}`}
                target="_blank"
              >
                <Button variant="ghost" size="sm">
                  <ExternalLink />
                  See on GitHub
                </Button>
              </a>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
export default RunTable
