import { useEffect } from "react"
import { useLocation, useSearch } from "wouter"

import { getAuthenticatedClient } from "@/api/client"
import Loading from "@/components/Loading"

const GithubSetup = () => {
  const search = useSearch()
  const [, setLocation] = useLocation()
  useEffect(() => {
    const installationId = new URLSearchParams(search).get("installation_id")
    if (!installationId) {
      return
    }
    getAuthenticatedClient()
      .github.setup.$post({
        json: { installationId },
      })
      .then(() => {
        setLocation("/")
      })
  }, [search, setLocation])
  return <Loading />
}

export default GithubSetup
