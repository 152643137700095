interface Props {
  Icon?: React.ComponentType
  actions?: React.ReactNode
  title: string
}

const PageTitle = ({ Icon, title, actions }: Props) => (
  <div className="flex gap-x-4 items-center font-bold text-2xl justify-between">
    <div className="flex items-center gap-x-2">
      {Icon ? <Icon /> : null}
      {title}
    </div>
    {actions}
  </div>
)

export default PageTitle
