import { zodResolver } from "@hookform/resolvers/zod"
import { useAtomValue } from "jotai"
import { Sparkles, X } from "lucide-react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import Editor from "react-simple-code-editor"
import { z } from "zod"

import { useGenerateExamplesMutation } from "@/api/rules"
import PageTitle from "@/components/PageTitle"
import { highlightCode } from "@/components/highlight"
import { LANGUAGE_OPTIONS } from "@/components/rule/languages"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Separator } from "@/components/ui/separator"
import { Textarea } from "@/components/ui/textarea"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { currentOrganisationAtom, currentRepositoryAtom } from "@/state"

export type Language = (typeof LANGUAGE_OPTIONS)[number]["value"]

const ruleFormSchema = z.object({
  name: z.string().min(1),
  description: z.string().min(1),
  instructions: z.string().min(1),
  fileRegex: z.string().optional(),
  language: z.string(),
  goodExamples: z.array(
    z.object({ content: z.string().min(1), isMagic: z.boolean() }),
  ),
  badExamples: z.array(
    z.object({ content: z.string().min(1), isMagic: z.boolean() }),
  ),
})

export interface RuleFormSchema {
  name: string
  description: string
  instructions: string
  fileRegex?: string
  language: Language
  goodExamples: { content: string; isMagic: boolean }[]
  badExamples: { content: string; isMagic: boolean }[]
}

interface Props {
  isPending?: boolean
  defaultValues: RuleFormSchema
  onSubmit: (data: RuleFormSchema) => void
}

const RuleForm = ({ defaultValues, onSubmit, isPending }: Props) => {
  const organisation = useAtomValue(currentOrganisationAtom)
  const repo = useAtomValue(currentRepositoryAtom)
  const generateExamplesMutation = useGenerateExamplesMutation(repo?.id)
  const { register, handleSubmit, control, setValue, watch } =
    useForm<RuleFormSchema>({
      defaultValues,
      resolver: zodResolver(ruleFormSchema),
    })

  const goodExamples = useFieldArray<RuleFormSchema, "goodExamples">({
    control,
    name: "goodExamples",
  })
  const badExamples = useFieldArray<RuleFormSchema>({
    control,
    name: "badExamples",
  })
  const magicallyAddExample = (
    {
      content,
      isGood,
    }: {
      content: string
      isGood: boolean
    },
    fieldIndex: number,
  ) => {
    let index = 0
    setInterval(() => {
      if (index <= content.length) {
        setValue(
          `${isGood ? "goodExamples" : "badExamples"}.${fieldIndex}.content`,
          content.slice(0, index),
        )
      }
      index++
    }, 15)
  }
  const canGenerate =
    watch("name") &&
    watch("description") &&
    watch("instructions") &&
    watch("language")
  const generateExamples = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const examples = await generateExamplesMutation.mutateAsync({
      name: watch("name"),
      description: watch("description"),
      instructions: watch("instructions"),
      language: watch("language"),
    })
    let goodExampleIndex = goodExamples.fields.length
    let badExampleIndex = badExamples.fields.length
    examples.forEach((example) => {
      if (example.isGood) {
        goodExamples.append(
          { content: "", isMagic: true },
          { shouldFocus: false },
        )
        magicallyAddExample(example, goodExampleIndex)
        goodExampleIndex++
      } else {
        badExamples.append(
          { content: "", isMagic: true },
          { shouldFocus: false },
        )
        magicallyAddExample(example, badExampleIndex)
        badExampleIndex++
      }
    })
    return false
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-y-4 w-full"
    >
      <PageTitle
        actions={
          <Button type="submit" size="lg" loading={isPending}>
            Submit
          </Button>
        }
        title={watch("name") ?? "New Rule"}
      />
      <div className="flex flex-col gap-y-2">
        <div className="flex w-full items-end">
          <Label htmlFor="name" className="flex flex-col gap-y-1 flex-1">
            <div>Name</div>
            <div className="text-xs font-normal">
              A short, descriptive name for the rule
            </div>
          </Label>
          <div className="text-xs text-accent-foreground">required</div>
        </div>
        <Input id="name" {...register("name")} placeholder="No console.log" />
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex w-full items-end">
          <Label htmlFor="description" className="flex flex-col gap-y-1 flex-1">
            <div>Description</div>
            <div className="text-xs font-normal">
              An explicit description of the rule and its purpose for everyone
              to understand
            </div>
          </Label>
          <div className="text-xs text-accent-foreground">required</div>
        </div>
        <Input
          id="description"
          {...register("description")}
          placeholder="Avoid using console.log in the code"
        />
      </div>
      <div className="flex flex-col gap-y-2">
        <Label htmlFor="language" className="flex flex-col gap-y-1">
          <div>Language</div>
          <div className="text-xs font-normal">
            The language this rule applies to.
          </div>
        </Label>
        <Controller
          name="language"
          control={control}
          render={({ field }) => (
            <Select
              value={field.value}
              onValueChange={(value) => field.onChange(value)}
            >
              <SelectTrigger id="language">
                <SelectValue placeholder="Select a language"></SelectValue>
              </SelectTrigger>
              <SelectContent>
                {LANGUAGE_OPTIONS.map(({ name, Icon, value }) => (
                  <SelectItem key={name} value={value}>
                    <div className="flex gap-x-2 items-center">
                      <div className="w-4">
                        <Icon />
                      </div>
                      {name}
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        />
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex w-full items-end">
          <Label htmlFor="description" className="flex flex-col gap-y-1 flex-1">
            <div>RegEx filter</div>
            <div className="text-xs font-normal">
              Only files matching this regular expression will be checked by
              this rule. Leave empty to check all files.
            </div>
          </Label>
        </div>
        <Input
          id="fileRegex"
          {...register("fileRegex")}
          placeholder="packages/(frontend|lib)/src/.*"
          className="font-mono"
        />
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex w-full items-end">
          <Label htmlFor="description" className="flex flex-col gap-y-1 flex-1">
            <div>Instructions</div>
            <div className="text-xs font-normal">
              How the rule should be applied and how the issue should be fixed.
              Don't hesitate to be as verbose as needed.
            </div>
          </Label>
          <div className="text-xs text-accent-foreground">required</div>
        </div>
        <Textarea
          id="instructions"
          {...register("instructions")}
          placeholder="Remove all console.log statements from the code"
        />
      </div>
      <Separator />
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-x-2 items-center mb-2">
          <div className="text-lg font-semibold">Examples</div>
          {organisation?.ruleGenerationEnabled ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    type="button"
                    size="sm"
                    onClick={generateExamples}
                    loading={generateExamplesMutation.isPending}
                    disabled={!canGenerate}
                  >
                    <Sparkles />
                    Generate
                  </Button>
                </TooltipTrigger>
                {!canGenerate ? (
                  <TooltipContent className="bg-black w-[150px]">
                    Please fill in the name, description, instructions, and
                    language first
                  </TooltipContent>
                ) : null}
              </Tooltip>
            </TooltipProvider>
          ) : undefined}
          {generateExamplesMutation.data &&
          generateExamplesMutation.data.length === 0 ? (
            <div className="text-sm">
              No examples could be generated. Please try again with different
              inputs.
            </div>
          ) : null}
        </div>
        <div className="text-medium text-md font-semibold">Good Examples</div>
        {goodExamples.fields.map((_, index) => (
          <div key={`goodExamples.${index}`} className="flex gap-x-4">
            <Controller
              key={`goodExamples.${index}`}
              control={control}
              name={`goodExamples.${index}.content`}
              render={({ field: { value, ref } }) => (
                <Editor
                  ref={ref}
                  value={value}
                  placeholder="// Add your example here"
                  onValueChange={(code) =>
                    setValue(`goodExamples.${index}.content`, code)
                  }
                  highlight={(code) => highlightCode(code, watch("language"))}
                  className="font-mono text-sm bg-green-100 w-full rounded-xl"
                  textareaClassName="rounded-xl outline-none"
                  padding={8}
                />
              )}
            />
            <Button
              variant="ghost"
              onClick={() => goodExamples.remove(index)}
              type="button"
            >
              <X />
            </Button>
          </div>
        ))}
        <div className="flex">
          <Button
            variant="secondary"
            size="sm"
            type="button"
            onClick={() =>
              goodExamples.append(
                { content: "", isMagic: false },
                { shouldFocus: false },
              )
            }
          >
            ✅ Add good example
          </Button>
        </div>
        <div className="text-medium text-md mt-2 font-semibold">
          Bad Examples
        </div>
        {badExamples.fields.map((_, index) => (
          <div key={`badExamples.${index}`} className="flex gap-x-4">
            <div className="flex w-full">
              <Controller
                control={control}
                name={`badExamples.${index}.content`}
                render={({ field: { value, ref } }) => (
                  <Editor
                    ref={ref}
                    value={value}
                    placeholder="// Add your example here"
                    onValueChange={(code) =>
                      setValue(`badExamples.${index}.content`, code)
                    }
                    highlight={(code) => highlightCode(code, watch("language"))}
                    className="font-mono text-sm  bg-red-100 w-full  rounded-xl"
                    textareaClassName="rounded-xl outline-none"
                    padding={8}
                  />
                )}
              />
            </div>
            <Button
              variant="ghost"
              onClick={() => badExamples.remove(index)}
              type="button"
            >
              <X />
            </Button>
          </div>
        ))}
        <div className="flex">
          <Button
            variant="secondary"
            size="sm"
            type="button"
            onClick={() =>
              badExamples.append(
                { content: "", isMagic: false },
                { shouldFocus: false },
              )
            }
          >
            ❌ Add bad example
          </Button>
        </div>
      </div>
    </form>
  )
}

export default RuleForm
