import { ReactNode } from "react"

interface Props {
  children?: ReactNode
  title?: ReactNode
  breadcrumbs?: {
    parents: React.ComponentPropsWithoutRef<"a"> &
      { name: string; href: string }[]
    current: React.ComponentPropsWithoutRef<"span"> & { name: string }
  }
}

const PageLayout = ({ children, title }: Props) => {
  return (
    <div className="w-full flex flex-col gap-y-4 pb-4">
      {title && <div className="px-4 font-bold text-2xl mb-4">{title}</div>}
      <div className="px-4">{children}</div>
    </div>
  )
}

export default PageLayout
