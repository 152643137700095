import { ResponseUser } from "api/src/auth/user"
import { useAtom } from "jotai"
import { Building2, ChevronsUpDown, Plus, User } from "lucide-react"

import PlanBadge from "@/components/PlanBadge"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar"
import { currentOrganisationAtom } from "@/state"

export function OrgSwitcher({ orgs }: { orgs: ResponseUser["organisations"] }) {
  const { isMobile } = useSidebar()
  const [activeOrg, setActiveOrg] = useAtom(currentOrganisationAtom)
  if (!activeOrg) return null

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate text-xs font-mono">
                  {activeOrg.name}
                </span>
              </div>
              <PlanBadge organisation={activeOrg} />
              <ChevronsUpDown className="ml-auto" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            align="start"
            side={isMobile ? "bottom" : "right"}
            sideOffset={4}
          >
            <DropdownMenuLabel className="text-xs text-muted-foreground">
              Organisations
            </DropdownMenuLabel>
            {orgs.map((org) => (
              <DropdownMenuItem
                key={org.name}
                onClick={() => setActiveOrg(org)}
                className="gap-2 p-2 cursor-pointer"
              >
                <div className="flex size-6 items-center justify-center rounded-sm border">
                  {org.type === "Organisation" ? (
                    <Building2 className="size-4 shrink-0" />
                  ) : (
                    <User className="size-4 shrink-0" />
                  )}
                </div>
                <div className="font-mono text-xs truncate">{org.name}</div>
                <PlanBadge organisation={org} />
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator />
            <DropdownMenuItem className="gap-2 p-2">
              <div className="flex size-6 items-center justify-center rounded-md border bg-background">
                <Plus className="size-4" />
              </div>
              <div className="font-medium">
                <a
                  href="https://github.com/apps/review-pal/installations/new"
                  target="_blank"
                >
                  Add Organisation
                </a>
              </div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}
