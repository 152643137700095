import { useMutation } from "@tanstack/react-query"
import { useSetAtom } from "jotai"
import { useState } from "react"

import { client } from "@/api/client"
import { setTokens } from "@/auth/jwt"
import Logo from "@/components/Logo"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { userAtom } from "@/state"

const AdminLogin = () => {
  const [email, setEmail] = useState("")
  const setUser = useSetAtom(userAtom)
  const { mutate: login, isPending } = useMutation({
    mutationFn: async (email: string) => {
      const res = await client.auth.admin.login.$post({
        json: { email },
      })
      if (!res.ok) {
        throw new Error(await res.text())
      }
      const { jwt, refreshToken, user } = await res.json()
      setUser(user)
      setTokens(jwt, refreshToken)
      window.location.href = "/"
    },
  })
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    login(email)
    return false
  }
  return (
    <form
      className="flex flex-col gap-y-2 items-center justify-center w-full h-full"
      onSubmit={handleSubmit}
    >
      <Card className="w-[500px]">
        <CardHeader className="w-full flex items-center">
          <Logo height={40} width={"200px"} />
        </CardHeader>
        <CardContent className="flex items-center justify-center pb-8 flex-col gap-y-4">
          <Input
            placeholder="john@smith.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button size="lg" disabled={!email} loading={isPending} type="submit">
            Login
          </Button>
        </CardContent>
      </Card>
    </form>
  )
}

export default AdminLogin
