import { useAtomValue } from "jotai"
import { Link, Redirect } from "wouter"

import { useRepositoryQuery } from "@/api/repo"
import Loading from "@/components/Loading"
import PageLayout from "@/components/PageLayout"
import Onboarding from "@/components/onboarding/Onboarding"
import RunTable from "@/components/run/RunTable"
import { Button } from "@/components/ui/button"
import { currentRepositoryAtom, userStateAtom } from "@/state"

const Repository = () => {
  const { user, isReady } = useAtomValue(userStateAtom)
  const repository = useAtomValue(currentRepositoryAtom)
  const { data, isLoading } = useRepositoryQuery(repository?.id)
  if (isLoading || !isReady) return <Loading />
  if (!user) return <Redirect to="/login" />
  const hasARepo = data !== undefined
  const hasARule = data?.rules.length !== 0
  const hasRuns = data?.lastRuns.length !== 0
  if (!hasARepo || !hasARule || !hasRuns)
    return (
      <Onboarding hasRepo={hasARepo} hasRules={hasARule} hasRuns={hasRuns} />
    )
  if (!data) return <Redirect to="/" />
  return (
    <PageLayout
      breadcrumbs={{
        parents: [{ name: "Home", href: "/" }],
        current: { name: data.fullName },
      }}
      title={
        <div className="flex gap-x-2 items-center font-bold text-2xl">
          <div>Repository</div>
          <div className="font-mono text-xs bg-gray-200 rounded-lg px-2 py-1">
            {data.fullName}
          </div>
        </div>
      }
    >
      <div className="flex flex-col gap-y-4">
        <div className="flex gap-y-2 flex-col">
          <div className="flex gap-x-4 items-center mb-2">
            <div className="text-lg font-semibold">Last Runs</div>
          </div>
          <RunTable repo={data} runs={data.lastRuns} />
          <div className="flex">
            <Link to={`/runs`}>
              <Button variant="secondary">See more</Button>
            </Link>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Repository
