import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query"

import { getAuthenticatedClient } from "@/api/client"
import { RuleFormSchema } from "@/components/rule/RuleForm"
import { JsonResponse } from "@/lib/api"
import { queryClient } from "@/lib/query"

export const useRepositoryQuery = (id?: string) =>
  useQuery({
    queryKey: ["repository", id],
    queryFn: () => {
      if (!id) return
      return getAuthenticatedClient()
        .repository[":repoId"].$get({ param: { repoId: id } })
        .then((res) => res.json())
    },
  })

export const useUpdateRule = (
  repoId?: string,
  ruleId?: string,
  options?: UseMutationOptions<
    JsonResponse<"repository/:repoId/rules/:ruleId/$put">,
    unknown,
    RuleFormSchema
  >,
) =>
  useMutation({
    ...options,
    mutationFn: async (data: RuleFormSchema) => {
      if (!repoId || !ruleId) throw new Error("repoId and ruleId are required")
      return getAuthenticatedClient()
        .repository[":repoId"].rules[":ruleId"].$put({
          param: { repoId, ruleId },
          json: {
            ...data,
            goodExamples: data.goodExamples
              .map(({ content }) => content)
              .filter(Boolean),
            badExamples: data.badExamples
              .map(({ content }) => content)
              .filter(Boolean),
          },
        })
        .then((res) => res.json())
    },
  })

export const useCreateRule = (
  repoId?: string,
  options?: UseMutationOptions<
    JsonResponse<"repository/:repoId/rules/$post">,
    unknown,
    RuleFormSchema
  >,
) =>
  useMutation({
    ...options,
    mutationFn: async (data: RuleFormSchema) => {
      if (!repoId) throw new Error("repoId is required")
      const response = await getAuthenticatedClient().repository[
        ":repoId"
      ].rules.$post({
        param: { repoId },
        json: {
          ...data,
          goodExamples: data.goodExamples
            .map(({ content }) => content)
            .filter(Boolean),
          badExamples: data.badExamples
            .map(({ content }) => content)
            .filter(Boolean),
        },
      })
      if (!response.ok) throw new Error("Failed to create rule")
      return response.json()
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context)
      queryClient.invalidateQueries({ queryKey: ["repository", repoId] })
    },
  })

export const useGetRunsQuery = (repoId?: string) =>
  useQuery({
    queryKey: ["runs", repoId],
    refetchInterval: 3000,
    queryFn: () => {
      if (!repoId) return
      return getAuthenticatedClient()
        .repository[":repoId"].runs.$get({
          param: { repoId },
        })
        .then((res) => {
          if (!res.ok) throw new Error("Failed to fetch pull requests")
          return res.json()
        })
    },
  })

export const useSearchPullRequestsQuery = (repoId?: string, q?: string) =>
  useQuery({
    queryKey: ["prs", repoId, q],
    enabled: !!repoId,
    queryFn: () => {
      if (!repoId) return
      return getAuthenticatedClient()
        .repository[":repoId"].prs.$get({
          param: { repoId },
          query: { q },
        })
        .then((res) => {
          if (!res.ok) throw new Error("Failed to fetch pull requests")
          return res.json()
        })
    },
  })

export const useTriggerNewRunMutation = (
  repoId?: string,
  options?: UseMutationOptions<
    JsonResponse<"repository/:repoId/prs/new-run/$post">,
    unknown,
    string
  >,
) =>
  useMutation({
    mutationFn: async (number: string) => {
      if (!repoId) throw new Error("repoId is required")
      const response = await getAuthenticatedClient().repository[":repoId"].prs[
        "new-run"
      ].$post({
        param: { repoId },
        json: { number },
      })
      if (!response.ok) throw new Error("Failed to trigger new run")
      return response.json()
    },
    ...options,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context)
      queryClient.invalidateQueries({ queryKey: ["runs"] })
    },
  })

export const useUpdateRepositorySettingsMutation = (
  repoId?: string,
  options?: UseMutationOptions<
    void,
    unknown,
    { runOnPrOpen: boolean; submitPrCheck: boolean }
  >,
) =>
  useMutation({
    mutationFn: async (data: {
      runOnPrOpen: boolean
      submitPrCheck: boolean
      runOnPrCommit: boolean
      disabled: boolean
    }) => {
      if (!repoId) return
      const response = await getAuthenticatedClient().repository[
        ":repoId"
      ].settings.$put({
        param: { repoId },
        json: data,
      })
      if (!response.ok) throw new Error("Failed to update settings")
    },
    ...options,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context)
      queryClient.invalidateQueries({ queryKey: ["repository", repoId] })
    },
  })

export const useDeleteRuleMutation = (
  options?: UseMutationOptions<
    void,
    unknown,
    { repoId: string; ruleId: string }
  >,
) =>
  useMutation({
    mutationFn: async ({
      repoId,
      ruleId,
    }: {
      repoId: string
      ruleId: string
    }) => {
      const response = await getAuthenticatedClient().repository[
        ":repoId"
      ].rules[":ruleId"].$delete({
        param: { repoId, ruleId },
      })
      if (!response.ok) throw new Error("Failed to delete rule")
    },
    ...options,
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data, variables, context)
      queryClient.invalidateQueries({ queryKey: ["repository"] })
    },
  })
