import { Button } from "@/components/ui/button"
import { Plus } from "lucide-react"

const AddToRepository = () => {
  return (
    <a
      href="https://github.com/apps/review-pal/installations/new"
      target="_blank"
    >
      <Button className="w-full" size="lg">
        <Plus />
        Add to a repository
      </Button>
    </a>
  )
}
export default AddToRepository
