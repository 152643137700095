import { useAtomValue } from "jotai"
import { Languages, Plus, Trash } from "lucide-react"
import { Link, Redirect } from "wouter"

import { useDeleteRuleMutation, useRepositoryQuery } from "@/api/repo"
import Loading from "@/components/Loading"
import PageLayout from "@/components/PageLayout"
import { LANGUAGE_OPTIONS } from "@/components/rule/languages"
import { Button } from "@/components/ui/button"
import { useToast } from "@/hooks/use-toast"
import { currentRepositoryAtom } from "@/state"

const RuleList = () => {
  const repository = useAtomValue(currentRepositoryAtom)
  const { toast } = useToast()
  const deleteRuleMutation = useDeleteRuleMutation({
    onSuccess: () => {
      toast({ title: "Rule deleted" })
    },
  })
  const { data, isLoading } = useRepositoryQuery(repository?.id)

  if (isLoading) return <Loading />
  if (!data) return <Redirect to="/" />

  return (
    <PageLayout
      title={
        <div className="flex gap-x-4 items-center font-bold text-2xl justify-between">
          <div className="flex items-center gap-x-2">
            <Languages />
            Rules
          </div>
          <Link to={`/rules/new`}>
            <Button>
              <Plus />
              Create a Rule
            </Button>
          </Link>
        </div>
      }
    >
      <div className="flex flex-col gap-y-4">
        {data.rules.map((rule) => {
          const language = LANGUAGE_OPTIONS.find(
            (lang) => lang.value === rule.language,
          )
          return (
            <div
              className="flex gap-x-2 justify-between items-center"
              key={rule.id}
            >
              <Link to={`/rules/${rule.id}`} className="flex-1">
                <div className="hover:bg-gray-100 py-2 px-2 rounded-sm flex flex-col gap-y-1">
                  <div className="text-sm font-semibold">{rule.name}</div>
                  <div className="text-xs text-gray-500">
                    {rule.description}
                  </div>
                  <div className="text-xs text-gray-500 flex gap-x-2">
                    {language ? (
                      <div className="flex gap-x-1">
                        <div className="w-4">
                          <language.Icon />
                        </div>
                        {language.name}
                      </div>
                    ) : null}
                    {language && rule.fileRegex ? <div> – </div> : null}
                    {rule.fileRegex ? (
                      <div className="font-mono">{rule.fileRegex}</div>
                    ) : null}
                  </div>
                </div>
              </Link>
              <Button
                variant="ghost"
                onClick={() => {
                  if (confirm("Delete this rule") && repository?.id) {
                    deleteRuleMutation.mutate({
                      repoId: repository.id,
                      ruleId: rule.id,
                    })
                  }
                }}
              >
                <Trash />
              </Button>
            </div>
          )
        })}
      </div>
    </PageLayout>
  )
}

export default RuleList
