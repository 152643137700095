import {
  CIcon,
  CPPIcon,
  DockerIcon,
  GoIcon,
  HTMLIcon,
  JavaIcon,
  JavascriptIcon,
  PHPIcon,
  PythonIcon,
  RubyIcon,
  RustIcon,
  ShellIcon,
  SQLIcon,
  SwiftIcon,
} from "@/components/rule/icons"

export const LANGUAGE_OPTIONS = [
  { name: "C", value: "C", Icon: CIcon },
  { name: "C++", value: "CPP", Icon: CPPIcon },
  { name: "Docker", value: "Dockerfile", Icon: DockerIcon },
  { name: "Go", value: "Go", Icon: GoIcon },
  { name: "HTML", value: "HTML", Icon: HTMLIcon },
  { name: "Java", value: "Java", Icon: JavaIcon },
  {
    name: "JavaScript / TypeScript",
    value: "Javascript",
    Icon: JavascriptIcon,
  },
  { name: "PHP", value: "PHP", Icon: PHPIcon },
  { name: "Python", value: "Python", Icon: PythonIcon },
  { name: "Ruby", value: "Ruby", Icon: RubyIcon },
  { name: "Rust", value: "Rust", Icon: RustIcon },
  { name: "Shell", value: "Shell", Icon: ShellIcon },
  { name: "SQL", value: "SQL", Icon: SQLIcon },
  { name: "Swift", value: "Swift", Icon: SwiftIcon },
] as const
