import { useAtomValue } from "jotai"

import PlanBadge from "@/components/PlanBadge"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { currentOrganisationAtom } from "@/state"

const ProPlan = () => {
  const organisation = useAtomValue(currentOrganisationAtom)

  return (
    <Card className="flex-1">
      <CardHeader>
        <CardTitle>Current plan</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-y-2">
          <div className="flex">
            <PlanBadge organisation={organisation} />
          </div>
          <div className="flex gap-x-2">
            <div className="flex text-muted-foreground">
              Runs used this month{" "}
            </div>
            <strong>
              {organisation?.subscription?.usedCredits}/
              {organisation?.subscription?.credits}
            </strong>
          </div>
          {organisation?.subscription?.renewsAt && (
            <div className="flex gap-x-2">
              <div className="flex text-muted-foreground">Renews on </div>
              <strong>
                {new Date(
                  organisation.subscription.renewsAt,
                ).toLocaleDateString()}
              </strong>
            </div>
          )}
          {organisation?.subscription?.expiresAt && (
            <div className="flex gap-x-2">
              <div className="flex text-muted-foreground">Expires on </div>
              <strong>
                {new Date(
                  organisation.subscription.expiresAt,
                ).toLocaleDateString()}
              </strong>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default ProPlan
