export const getTokens = () => {
  const jwt = localStorage.getItem("jwt")
  const refreshToken = localStorage.getItem("refreshToken")
  return { jwt, refreshToken }
}

export const setTokens = (jwt: string, refreshToken: string) => {
  localStorage.setItem("jwt", jwt)
  localStorage.setItem("refreshToken", refreshToken)
}
