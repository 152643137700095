import { useMutation, UseMutationOptions } from "@tanstack/react-query"

import { getAuthenticatedClient } from "@/api/client"
import { JsonResponse } from "@/lib/api"

export const useGenerateExamplesMutation = (
  repoId?: string,
  options?: UseMutationOptions<
    JsonResponse<"repository/:repoId/generate-examples/$post">,
    unknown,
    {
      name: string
      description: string
      language?: string
      instructions: string
    }
  >,
) => {
  return useMutation({
    ...options,
    mutationFn: async (args: {
      name: string
      description: string
      language?: string
      instructions: string
    }) => {
      if (!repoId) throw new Error("repoId is required")
      const response = await getAuthenticatedClient().repository[":repoId"][
        "generate-examples"
      ].$post({
        param: { repoId: repoId },
        json: args,
      })
      if (!response.ok) throw new Error(response.statusText)
      return response.json()
    },
  })
}
