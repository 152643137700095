import { useAtomValue } from "jotai"
import { AreaChart } from "lucide-react"

import PageLayout from "@/components/PageLayout"
import PageTitle from "@/components/PageTitle"
import ProPlan from "@/features/settings/ProPlan"
import TrialPlan from "@/features/settings/TrialPlan"
import { currentOrganisationAtom } from "@/state"

const Usage = () => {
  const organisation = useAtomValue(currentOrganisationAtom)
  return (
    <PageLayout title={<PageTitle title="Usage" Icon={AreaChart} />}>
      <div className="flex gap-x-4">
        {!organisation?.subscription ? null : organisation?.subscription
            ?.type === "Pro" ? (
          <ProPlan />
        ) : (
          <TrialPlan />
        )}
      </div>
    </PageLayout>
  )
}

export default Usage
