import { useAtomValue } from "jotai"
import { Redirect } from "wouter"

import { useRepositoryQuery, useUpdateRule } from "@/api/repo"
import Loading from "@/components/Loading"
import PageLayout from "@/components/PageLayout"
import RuleForm, {
  RuleFormSchema as Form,
  Language,
} from "@/components/rule/RuleForm"
import { useToast } from "@/hooks/use-toast"
import { currentRepositoryAtom } from "@/state"

interface Props {
  params: {
    ruleId: string
  }
}

const Rule = ({ params: { ruleId } }: Props) => {
  const { toast } = useToast()
  const repository = useAtomValue(currentRepositoryAtom)
  const { data: repo, isLoading } = useRepositoryQuery(repository?.id)

  const { mutate, isPending } = useUpdateRule(repo?.id, ruleId, {
    onSuccess: () => toast({ title: "Rule updated" }),
    onError: () => toast({ title: "Error updating rule" }),
  })

  const rule = repo?.rules.find((rule) => rule.id === ruleId)

  if (isLoading) return <Loading />

  if (!rule || !repo) return <Redirect to={`/`} />

  const onSubmit = (data: Form) => {
    mutate(data)
  }
  return (
    <PageLayout>
      <RuleForm
        defaultValues={{
          name: rule.name,
          description: rule.description ?? "",
          instructions: rule.instructions ?? "",
          goodExamples: rule.examples.filter(({ isGood }) => isGood),
          badExamples: rule.examples.filter(({ isGood }) => !isGood),
          language: rule.language as Language,
        }}
        onSubmit={onSubmit}
        isPending={isPending}
      />
    </PageLayout>
  )
}

export default Rule
