import { ResponseUser } from "api/src/auth/user"

import { Badge } from "@/components/ui/badge"

interface Props {
  organisation?: ResponseUser["organisations"][number]
}

const PlanBadge = ({ organisation }: Props) => {
  if (!organisation?.subscription) {
    return null
  }
  return (
    <Badge
      variant={
        organisation.subscription.type === "Pro" ? undefined : "secondary"
      }
    >
      {organisation.subscription.type === "Pro" ? "Pro" : "Trial"}
    </Badge>
  )
}
export default PlanBadge
