import { Crisp } from "crisp-sdk-web"
import { useAtom, useAtomValue } from "jotai"
import {
  ChartArea,
  ChevronDown,
  ChevronsUpDown,
  CirclePlay,
  Home,
  Languages,
  LogOut,
  Settings,
} from "lucide-react"
import { useEffect } from "react"
import { Link } from "wouter"

import AddToRepository from "@/components/AddToRepository"
import Logo from "@/components/Logo"
import { OrgSwitcher } from "@/components/sidebar/OrgSwitcher"
import SidebarLink from "@/components/sidebar/SidebarLink"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar"
import {
  currentOrganisationAtom,
  currentRepositoryAtom,
  userStateAtom,
} from "@/state"

const AppSidebar = () => {
  const { user, isReady } = useAtomValue(userStateAtom)
  const currentOrg = useAtomValue(currentOrganisationAtom)
  const [repository, setRepository] = useAtom(currentRepositoryAtom)

  const items = repository
    ? [
        { title: "Home", url: `/`, icon: Home },
        {
          title: "Rules",
          url: `/rules`,
          icon: Languages,
        },
        {
          title: "Runs",
          url: `/runs`,
          icon: CirclePlay,
        },
        {
          title: "Settings",
          url: `/repo/settings`,
          icon: Settings,
        },
      ]
    : []
  const { isMobile } = useSidebar()
  useEffect(() => {
    if (!currentOrg || !repository) return
    Crisp.session.setData({
      org_id: currentOrg.id,
      org_name: currentOrg.name,
      repo_id: repository.id,
      repo_name: repository.name,
    })
  }, [currentOrg, repository])

  return (
    <Sidebar>
      <SidebarHeader>
        <Link
          to="/"
          className="font-logo font-bold text-2xl flex justify-center py-2"
        >
          <Logo height={30} />
        </Link>
        <OrgSwitcher orgs={user?.organisations ?? []} />
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>Repository</SidebarGroupLabel>
          <SidebarGroupContent>
            {!isReady ? null : (user?.organisations.length ?? 0) > 0 ? (
              <DropdownMenu>
                <DropdownMenuTrigger asChild className="">
                  <SidebarMenuButton className="my-2">
                    {repository ? (
                      <pre className="text-xs">{repository.name}</pre>
                    ) : (
                      "Select Repository"
                    )}
                    <ChevronDown className="ml-auto" />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-[--radix-popper-anchor-width]">
                  {currentOrg?.repositories.map((repo) => (
                    <DropdownMenuItem
                      key={repo.id}
                      onClick={() => setRepository(repo)}
                    >
                      <pre className="font-mono text-xs">{repo.name}</pre>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <AddToRepository />
            )}
            <SidebarMenu>
              {items.map((item) => (
                <SidebarLink key={item.title} item={item} />
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarGroupLabel>Settings</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarLink
                item={{
                  title: "Usage",
                  icon: ChartArea,
                  url: "/settings/usage",
                }}
              ></SidebarLink>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <SidebarMenuButton
                  size="lg"
                  className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                >
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">{user?.name}</span>
                    <span className="truncate text-xs">{user?.email}</span>
                  </div>
                  <ChevronsUpDown className="ml-auto size-4" />
                </SidebarMenuButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                side={isMobile ? "bottom" : "right"}
                align="end"
                sideOffset={4}
              >
                <DropdownMenuLabel className="p-0 font-normal">
                  <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                    <div className="grid flex-1 text-left text-sm leading-tight">
                      <span className="truncate font-semibold">
                        {user?.name}
                      </span>
                      <span className="truncate text-xs">{user?.email}</span>
                    </div>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <Link to="/logout">
                  <DropdownMenuItem className="cursor-pointer">
                    <LogOut />
                    Log out
                  </DropdownMenuItem>
                </Link>
              </DropdownMenuContent>
            </DropdownMenu>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
    </Sidebar>
  )
}

export default AppSidebar
