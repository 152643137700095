import hljs from "highlight.js/lib/core"
import shell from "highlight.js/lib/languages/bash"
import c from "highlight.js/lib/languages/c"
import cpp from "highlight.js/lib/languages/cpp"
import docker from "highlight.js/lib/languages/dockerfile"
import go from "highlight.js/lib/languages/go"
import java from "highlight.js/lib/languages/java"
import javascript from "highlight.js/lib/languages/javascript"
import php from "highlight.js/lib/languages/php"
import python from "highlight.js/lib/languages/python"
import ruby from "highlight.js/lib/languages/ruby"
import rust from "highlight.js/lib/languages/rust"
import sql from "highlight.js/lib/languages/sql"
import swift from "highlight.js/lib/languages/swift"
import html from "highlight.js/lib/languages/xml"
import "highlight.js/styles/github.css"

hljs.registerLanguage("java", java)
hljs.registerLanguage("py", python)
hljs.registerLanguage("js", javascript)
hljs.registerLanguage("c", c)
hljs.registerLanguage("cpp", cpp)
hljs.registerLanguage("docker", docker)
hljs.registerLanguage("go", go)
hljs.registerLanguage("markup", html)
hljs.registerLanguage("php", php)
hljs.registerLanguage("ruby", ruby)
hljs.registerLanguage("rust", rust)
hljs.registerLanguage("bash", shell)
hljs.registerLanguage("sql", sql)
hljs.registerLanguage("swift", swift)

export const highlightCode = (code: string, language: string | undefined) => {
  switch (language) {
    case "Java":
      return hljs.highlight(code, { language: "java" }).value
    case "Python":
      return hljs.highlight(code, { language: "py" }).value
    case "Javascript":
      return hljs.highlight(code, { language: "js" }).value
    case "C":
      return hljs.highlight(code, { language: "c" }).value
    case "CPP":
      return hljs.highlight(code, { language: "cpp" }).value
    case "Dockerfile":
      return hljs.highlight(code, { language: "docker" }).value
    case "Go":
      return hljs.highlight(code, { language: "go" }).value
    case "HTML":
      return hljs.highlight(code, { language: "markup" }).value
    case "PHP":
      return hljs.highlight(code, { language: "php" }).value
    case "Ruby":
      return hljs.highlight(code, { language: "ruby" }).value
    case "Rust":
      return hljs.highlight(code, { language: "rust" }).value
    case "Shell":
      return hljs.highlight(code, { language: "bash" }).value
    case "SQL":
      return hljs.highlight(code, { language: "sql" }).value
    case "Swift":
      return hljs.highlight(code, { language: "swift" }).value
    default:
      return hljs.highlight(code, { language: "js" }).value
  }
}
