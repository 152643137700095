import { useAtomValue } from "jotai"
import { Redirect } from "wouter"

import { useCreateRule } from "@/api/repo"
import PageLayout from "@/components/PageLayout"
import RuleForm, { RuleFormSchema } from "@/components/rule/RuleForm"
import { useToast } from "@/hooks/use-toast"
import { currentRepositoryAtom } from "@/state"

const defaultValues: RuleFormSchema = {
  name: "",
  description: "",
  instructions: "",
  goodExamples: [],
  badExamples: [],
  language: "Javascript",
}

const NewRule = () => {
  const repository = useAtomValue(currentRepositoryAtom)
  const { toast } = useToast()
  const { mutate, isPending } = useCreateRule(repository?.id, {
    onSuccess: (data) => {
      window.location.href = `/rules/${data.id}`
      toast({ title: "Rule created" })
    },
  })
  if (!repository) return <Redirect to="/" />
  return (
    <PageLayout>
      <RuleForm
        defaultValues={defaultValues}
        onSubmit={mutate}
        isPending={isPending}
      />
    </PageLayout>
  )
}
export default NewRule
