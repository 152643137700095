import { InfinitySpin } from "react-loader-spinner"

const Loading = () => {
  return (
    <div className="flex gap-x-2 items-center">
      <InfinitySpin width="80" color="#333" />
      <div>Loading...</div>
    </div>
  )
}

export default Loading
