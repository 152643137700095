import { useEffect } from "react"
import { useLocation } from "wouter"

import Loading from "@/components/Loading"

const Logout = () => {
  const [, setLocation] = useLocation()
  useEffect(() => {
    localStorage.removeItem("jwt")
    localStorage.removeItem("refreshToken")
    setLocation("/login")
  }, [setLocation])
  return (
    <div className="flex items-center justify-center w-full">
      <Loading />
    </div>
  )
}

export default Logout
