import { useQuery } from "@tanstack/react-query"
import { ResponseUser } from "api/src/auth/user"
import { Crisp } from "crisp-sdk-web"
import { atom, useSetAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"

import { getAuthenticatedClient } from "@/api/client"

export const userAtom = atom<ResponseUser>()

export const jwtAtom = atom(localStorage.getItem("jwt"))
export const refreshTokenAtom = atom(localStorage.getItem("refreshToken"))
export const tokensAtom = atom(null, (_, set, { jwt, refreshToken }) => {
  set(jwtAtom, jwt)
  set(refreshTokenAtom, refreshToken)
})
export const isReadyAtom = atom(false)

const getInitialLocalStorageValue = (key: string) => {
  try {
    const storageOrganisationId = localStorage.getItem(key)
    if (storageOrganisationId && storageOrganisationId !== "null") {
      return JSON.parse(storageOrganisationId)
    }
    return null
  } catch {
    return null
  }
}

export const currentOrganisationIdAtom = atomWithStorage<string | null>(
  "currentOrganisationId",
  getInitialLocalStorageValue("currentOrganisationId"),
)
export const currentOrganisationAtom = atom(
  (get) => {
    const user = get(userAtom)
    const currentOrganisationId = get(currentOrganisationIdAtom)
    if (!user) return undefined
    return (
      user?.organisations.find((o) => o.id === currentOrganisationId) ??
      user?.organisations[0]
    )
  },
  (_, set, newOrganisation: { id: string; repositories: { id: string }[] }) => {
    set(currentOrganisationIdAtom, newOrganisation.id)
    set(currentRepositoryIdAtom, newOrganisation.repositories[0].id)
  },
)

export const currentRepositoryIdAtom = atomWithStorage<string | null>(
  "currentRepositoryId",
  getInitialLocalStorageValue("currentRepositoryId"),
)
export const currentRepositoryAtom = atom(
  (get) => {
    const currentOrganisation = get(currentOrganisationAtom)
    const currentRepositoryId = get(currentRepositoryIdAtom)
    return (
      currentOrganisation?.repositories.find(
        (r) => r.id === currentRepositoryId,
      ) ?? currentOrganisation?.repositories[0]
    )
  },
  (_, set, newRepository: { id: string }) => {
    set(currentRepositoryIdAtom, newRepository.id)
  },
)

export const userStateAtom = atom((get) => {
  const user = get(userAtom)
  const jwt = get(jwtAtom)
  const refreshToken = get(refreshTokenAtom)
  const isReady = get(isReadyAtom)
  return { user, jwt, refreshToken, isReady }
})

export const useFetchMe = () => {
  const setUser = useSetAtom(userAtom)
  const setIsReady = useSetAtom(isReadyAtom)
  return useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      try {
        const res = await getAuthenticatedClient().auth.me.$get()
        if (res.status === 401) {
          localStorage.removeItem("jwt")
          localStorage.removeItem("refreshToken")
          return null
        }
        const user = await res.json()
        setUser(user)
        if (user.email) Crisp.user.setEmail(user.email)
        Crisp.user.setNickname(user.name)
        return user
      } finally {
        setIsReady(true)
      }
    },
  })
}
