import { Link, useRoute } from "wouter"

import { SidebarMenuButton, SidebarMenuItem } from "@/components/ui/sidebar"

interface Props {
  item: {
    title: string
    url: string
    icon: React.ComponentType
  }
}

const SidebarLink = ({ item }: Props) => {
  const [isActive] = useRoute(item.url)

  return (
    <SidebarMenuItem key={item.title}>
      <SidebarMenuButton asChild isActive={isActive}>
        <Link to={item.url}>
          <item.icon />
          <span>{item.title}</span>
        </Link>
      </SidebarMenuButton>
    </SidebarMenuItem>
  )
}

export default SidebarLink
