import { tokensAtom, userAtom } from "../state"
import { useSetAtom } from "jotai"
import { useEffect, useRef } from "react"
import { useLocation, useSearch } from "wouter"

import { client } from "@/api/client"
import Loading from "@/components/Loading"

import { setTokens } from "./jwt"

const retrieveToken = async (code: string) => {
  return client.auth.github.callback
    .$post({ json: { code } })
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
      throw new Error("Error upserting user")
    })
    .then(({ jwt, refreshToken, user }) => {
      setTokens(jwt, refreshToken)
      return { jwt, refreshToken, user }
    })
}

const GithubCallback = () => {
  const search = useSearch()
  const fetching = useRef(false)
  const [, setLocation] = useLocation()
  const setUser = useSetAtom(userAtom)
  const setTokens = useSetAtom(tokensAtom)
  useEffect(() => {
    if (fetching.current) {
      return
    }
    fetching.current = true
    const code = new URLSearchParams(search).get("code")
    if (!code) {
      return
    }
    retrieveToken(code)
      .then(({ user, jwt, refreshToken }) => {
        setUser(user)
        setTokens({ jwt, refreshToken })
        setLocation("/")
      })
      .catch(() => {
        const errorMessage =
          "You could not be logged in. Please try again later."
        setLocation(`/login?error=${errorMessage}`)
      })
  }, [search, setLocation, setTokens, setUser])
  return (
    <div className="flex items-center justify-center w-full">
      <Loading />
    </div>
  )
}

export default GithubCallback
